<template>
	<footer>
      <div class="footer__area footer-bg">
         <div class="footer__top pt-40 pb-20">
            <div class="container">
               <div class="row">
                  <div class="col-12">
                     <div class="row align-items-center">
                        <div class="col-12 col-md-4 text-center">
                           <div class="footer__widget">
                              <div class="footer__widget-title">
                                 <div class="footer__logo">
                                    <a href="javascript:;">
                                       <img :src="config.logoWhite == null ? '' : config.logoWhite" alt="Logo branca" @error="logoError">
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6 col-md-4 text-center" v-if="config.urlDiscord != null && config.urlDiscord.trim().length > 0">
                           <div class="footer__widget">
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li class="footer__widget-title">
                                          <a class="p-0" :href="config.urlDiscord" target="_blank" title="Visite nossa página">
                                             <i class="fab fa-discord font-20 color-theme-secondary"></i>
                                             <h4 class="pt-1">Discord</h4>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6 col-md-4 text-center" v-if="config.urlTiktok != null && config.urlTiktok.trim().length > 0">
                           <div class="footer__widget">
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li class="footer__widget-title">
                                          <a class="p-0" :href="config.urlTiktok" target="_blank" title="Visite nossa página">
                                             <i class="fab fa-tiktok font-20 color-theme-secondary"></i>
                                             <h4 class="pt-1">TikTok</h4>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6 col-md-4 text-center" v-if="config.urlYoutube != null && config.urlYoutube.trim().length > 0">
                           <div class="footer__widget">
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li class="footer__widget-title">
                                          <a class="p-0" :href="config.urlYoutube" target="_blank" title="Visite nossa página">
                                             <i class="fab fa-youtube font-20 color-theme-secondary"></i>
                                             <h4 class="pt-1">YouTube</h4>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6 col-md-4 text-center" v-if="config.urlInstagram != null && config.urlInstagram.trim().length > 0">
                           <div class="footer__widget">
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li class="footer__widget-title">
                                          <a class="p-0" :href="config.urlInstagram" target="_blank" title="Visite nossa página">
                                             <i class="fab fa-instagram font-20 color-theme-secondary"></i>
                                             <h4 class="pt-1">Instagram</h4>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-md-4 text-center" v-if="config.urlFacebook != null && config.urlFacebook.trim().length > 0">
                           <div class="footer__widget">
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li class="footer__widget-title">
                                          <a class="p-0" :href="config.urlFacebook" target="_blank" title="Visite nossa página">
                                             <i class="fab fa-facebook-f font-20 color-theme-secondary"></i>
                                             <h4 class="pt-1">Facebook</h4>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="footer__bottom pt-60 pb-60"  v-if="config.descricao4 != null && config.descricao4.trim().length > 0">
            <div class="container">
               <div class="row">
                  <div class="col-xl-12">
                     <div class="footer__links text-center">
                        <p>{{ config.descricao4 }}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="footer__copyright pt-30 pb-30 footer-bottom-bg">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-12">
                     <div class="footer__copyright-text">
                        <p class="text-center">Copyright © <a href="https://agenciaoneway.com/" target="_blank">OneWay Systems.</a> Todos os direitos reservados.</p>
                     </div>
                  </div>
                  <div class="col-12 mt-3">
                     <div class="footer__copyright-text">
                        <p class="text-center font-10">
                           Os preços e condições estão sujeitos a disponibilidade de estoque e alteração de preço sem aviso prévio. 
                           <br>Imagens dos produtos são meramente ilustrativas.
                           <br>Venda sujeita à disponibilidade de estoque no dia da entrega.
                           <br>No caso de faltar algum produto, este não será entregue e o valor correspondente não será cobrado.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Footer',
   computed: {
		... mapState({
			config: state => state.config
		})
	},
   methods: {
      logoError : function (e) {
         e.target.src = 'https://cdn.discordapp.com/attachments/795099022439481390/1003983121026396210/logo-white.png';
      }
   }
}

</script>